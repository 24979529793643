import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Box from "@material-ui/core/Box";

export default function Service({ data: { service } }) {
  return (
    <Layout>
      <SEO title={service.frontmatter.title} description={service.excerpt} />

      <Container maxWidth="md">
        <Box p={3}>
          <Typography variant="h3" component="h2" gutterBottom>
            {service.frontmatter.title}
          </Typography>

          <MDXRenderer>{service.body}</MDXRenderer>
        </Box>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ServiceQuery($language: String, $id: String) {
    service: mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
      excerpt
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
